<template>
  <div>
    <el-dialog
      title="共享广告账号"
      :visible="shareAccountShow"
      width="800px"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        :model="numberValidateForm"
        ref="numberValidateForm"
        label-width="80px"
        class="demo-ruleForm"
        size="small"
        :rules="rules"
      >
        <el-form-item label="广告账号" prop="toAccountIds">
          <el-cascader v-model="numberValidateForm.toAccountIds" :props="props" :options="numList" placeholder="请选择广告账号" style="width:100%" clearable ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini">取 消</el-button>
        <el-button type="primary" size="mini" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { adaccouts } from "@/api/common/user";
import { mapState, mapGetters } from "vuex";
import * as API from "@/api/customAudience.js";
export default {
  props: {
    shareAccountShow: {
      type: Boolean,
      default: false,
    },
    audience_id:{
      type:String,
      default:''
    }
  },
  computed: {
    ...mapGetters("num", ["validNumLists"]),
    numList(){
      return this.validNumLists
    }
  },
  data() {
    return {
      numberValidateForm: {},
      props:{
        multiple:true,
        lazy:true,
        value:'id',
        label:'name',
        lazyLoad:this.getAccountByNum
      },
      rules:{
        toAccountIds:[
          {required:true,message:'请选择广告账号',trigger:'change'}
        ]
      }
    };
  },
  methods: {
    async getAccountByNum(node,resolve){
      if(node.data.leaf){
        resolve([]);
        return;
      }
        let _params = {
            keyword: "",
            limit: 10000,
            page: 1,
            id:node.data.id, //个号id
        };
        let res = await adaccouts(_params)
        if (res.code == 200) {
          let list = res.data.list.map(v => {
            v.leaf = true;
            return {
              id:v.id,
              name:v.name,
              leaf:true
            };
          });
          resolve(list);
        }else{
          resolve([]);
        }
    },
    handleClose() {
      this.$emit("update:shareAccountShow", false);
    },
    confirm() {
      this.$refs.numberValidateForm.validate(v=>{
        if(v){
          let list = this.numberValidateForm.toAccountIds.map(v=>v[1]);
          this.$showLoading()
          API.shareAddToAccounts({toAccountIds:list,audienceId:this.audience_id}).then(res=>{
            this.$hideLoading()
            if(res.code == 0){
              this.$message({
                type:'success',
                message:'添加成功'
              })
              this.handleClose()
            }
          })
        }else{
          
        }
      })
      
    },
  },
};
</script>

<style>
</style>