<template>
  <div class="entity-manage bg-f">
    <div class="search-form">
      <el-form
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="">
          <el-input clearable placeholder="id" v-model="searchForm.id">
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input clearable placeholder="名称" v-model="searchForm.name">
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-select
            filterable
            clearable
            placeholder="类型"
            v-model="searchForm.subtype"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in subType"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            filterable
            clearable
            placeholder="品类"
            v-model="searchForm.category"
          >
            <el-option
              v-for="item in cateList"
              :key="item.id"
              :label="item.full_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            filterable
            clearable
            placeholder="地区"
            v-model="searchForm.country"
          >
            <el-option
              v-for="(item, index) in data_obj.TargetingCountry"
              :key="index"
              :label="item.name"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            filterable
            clearable
            placeholder="共享人"
            v-model="searchForm.userId"
            remote
            :remote-method="remoteMethod"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in userList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="operate-area dp-flex align-item-c p-16">
      <div class="mr-10"><i class="el-icon-s-operation mr-5"></i>数据列表</div>
    </div>
    <div class="table-wrap">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :height="tableHeight"
        @selection-change="selectChange"
      >
        <el-table-column prop="id" label="id/名称/类型" width="260px">
          <template slot-scope="scope">
            {{ scope.row.name }}({{ scope.row.id }})/{{ scope.row.subtype?scope.row.subtype.desc:'' }}
          </template>
        </el-table-column>
        <el-table-column prop="description" label="描述" width="200px"> </el-table-column>
        <el-table-column prop="familyName" label="品类标签" width="260px">
            <template slot-scope="scope">
              <el-tag v-for="item in scope.row.category" :key="item.id">{{item.name}}</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="familyName" label="地区标签" width="260px">
          <template slot-scope="scope">
              <el-tag v-for="item in scope.row.country" :key="item.id">{{item.name}}</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="time_updated" label="受众更新时间"  width="120px"></el-table-column>
        <el-table-column
          prop="operation_status"
          label="可投放状态"
          width="160px"
        >
          <template slot-scope="scope">
            {{
              scope.row.operation_status
                ? scope.row.operation_status.description
                : "--"
            }}
          </template>
        </el-table-column>
        <el-table-column label="规模" width="200px">
          <template slot-scope="scope">
            {{ scope.row.approximate_count_lower_bound }}~{{
              scope.row.approximate_count_upper_bound
            }}
          </template>
        </el-table-column>
        <el-table-column prop="retention_days" label="留存率（天）">
        </el-table-column>
        <el-table-column prop="delivery_status" label="受众状态" width="160px">
          <template slot-scope="scope">
            {{
              scope.row.delivery_status
                ? scope.row.delivery_status.description
                : "--"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="shareUser" label="共享人"> </el-table-column>
        <!-- <el-table-column prop="seq_no" label="状态"> </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="creatShareAudience(scope.row)"
            ></el-button>
            <el-button
              type="text"
              icon="el-icon-share"
              @click="addShareAccount(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page" style="margin-top: 10px; text-align: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[1, 10, 20, 30, 40, 50, 80, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="共享受众"
      :visible.sync="shareAudienceShow"
      width="800px"
      @close="close('shareAudienceShow')"
      :close-on-click-modal="false"
    >
      <share-audience :data_obj="data_obj" @clear="close('shareAudienceShow')" :audience_id="audience_id" :rowMsg="rowMsg" @getList="getList"></share-audience>
    </el-dialog>
    <share-account :shareAccountShow.sync="shareAccountShow" :audience_id="audience_id"></share-account>
  </div>
</template>
<script>
import { tableHeight, fnCSTToYmd } from "@/utils/utils.js";
import { adConstsMap } from "@/api/creatAd.js";
import { pages, queryAll } from "@/api/assetManage/bm";
import shareAudience from "@/views/customAudience/shareAudience/index.vue";
import shareAccount from "@/views/shareAudience/shareAccount.vue";
import * as API from "@/api/customAudience.js";
import viewDetailVue from '../openAccount/components/dialog/viewDetail.vue';
let cateList = require("../customAudience/categoryAudience/cate.json");
export default {
  data() {
    return {
      searchForm: {},
      tableHeight: 600,
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      areaList: [],
      addVisible: false,
      title: "添加",
      rowMsg: null,
      addBlackVisible: false,
      multipleSelection: [],
      teams: [],
      subjectList: [],
      shareAudienceShow: false,
      shareAccountShow: false,
      initData: null,
      cateList: cateList.list.filter((v) => v.level == 1 || v.level == 2),
      userList:[],
      audience_id:null,
      subType:[
        {id:'CUSTOM',name:'客户文件自定义受众'},
        {id:'WEBSITE',name:'网站自定义受众'},
        {id:'APP',name:'APP'},
        {id:'OFFLINE_CONVERSION',name:'OFFLINE_CONVERSION'},
        {id:'CLAIM',name:'CLAIM'},
        {id:'PARTNER',name:'PARTNER'},
        {id:'MANAGED',name:'MANAGED'},
        {id:'VIDEO',name:'VIDEO'},
        {id:'LOOKALIKE',name:'类似受众'},
        {id:'ENGAGEMENT',name:'ENGAGEMENT'},
        {id:'BAG_OF_ACCOUNTS',name:'BAG_OF_ACCOUNTS'},
        {id:'STUDY_RULE_AUDIENCE',name:'STUDY_RULE_AUDIENCE'},
        {id:'FOX',name:'FOX'},
        {id:'MEASUREMENT',name:'MEASUREMENT'},
        {id:'REGULATED_CATEGORIES_AUDIENCE',name:'REGULATED_CATEGORIES_AUDIENCE'},
      ]
    };
  },
  components: {
    shareAudience,
    shareAccount,
  },
  computed: {
    data_obj() {
      console.log(this.$store);
      let accountId = this.$store.getters.accountId;
      let initData = this.initData?.targeting.TargetingCountry;
      return {
        accountId: accountId,
        TargetingCountry: initData,
      };
    },
  },
  methods: {
    async getInitData() {
      await adConstsMap().then((res) => {
        this.$store.commit("initData/setInitData", res.data);
        this.initData = res.data;
      });
    },
    remoteMethod(v){
      this.getUser(v);
    },
    getUser(v){
      let params = {
        type:'gk_user',
        fields:["id" , "name" , "org_code"],
        page_size:10000,
        filters:[
            {
            property:"name",
            ops:"like",
            value: v  
            }
        ],
      }
      API.gimpQuery(params).then(res=>{
        this.userList = res.data.list
      })
    },
    close(v) {
      this[v] = false;
    },
    sure() {},
    addShareAccount(row) {
      this.audience_id = row.id
      this.shareAccountShow = true;
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    selectChange(val) {
      this.multipleSelection = [...val];
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    // 点击编辑分享受众
    creatShareAudience(row) {
      this.shareAudienceShow = true;
      this.rowMsg = {...row}
      this.audience_id = row.id
    },
    getList() {
      console.log(this.searchForm);
      let forms = JSON.parse(JSON.stringify(this.searchForm))
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        ...forms,
      };
      for (let key in params) {
        if (!params[key]) {
          params[key] = null;
        }
      }
      API.sharedAudiences(params).then((res) => {
        console.log(res);
        this.tableData = res.data.content;
        this.total = res.data.totalElements;
      });
    },
  },
  mounted() {
    this.getInitData();
    this.getList();
    // this.getFamily();
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/commonly";
.entity-manage {
  .table-wrap {
    padding: 16px;
    .el-tag{
      margin: 5px;
    }
  }
  .el-form {
    padding: 16px 16px 0;
  }
  .bar {
    height: 16px;
    background-color: #f5f5f5;
  }
  .operate-area {
    background-color: #fafafa;
  }
  .entity-table {
    th {
      background-color: #fafafa;
    }
  }
}
</style>
